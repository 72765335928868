import { BaseApiModel } from 'app/models/api/response';

const countryCodeMap = new Map([
    ['ar', 'Argentina'],
    ['be', 'Belgium'],
    ['br', 'Brazil'],
    ['ca', 'Canada'],
    ['co', 'Colombia'],
    ['de', 'Germany'],
    ['dk', 'Denmark'],
    ['fi', 'Finland'],
    ['in', 'India'],
    ['it', 'Italy'],
    ['my', 'Malaysia'],
    ['mx', 'Mexico'],
    ['nl', 'Netherlands'],
    ['no', 'Norway'],
    ['es', 'Spain'],
]);

export class Country extends BaseApiModel {
    get name() {
        return countryCodeMap.get(this.countryCode);
    }

    countryCode = '';
}
