import { GemUserRole } from 'app/models/api/gem-user';
import { allGemUserRoles, GemUser } from 'app/models/api/gem-user';
import { GemUserApiService } from 'app/services/api/gem-user.api.service';
import { BaseComponent } from 'app/components/base.component';
import { Component, OnInit, ChangeDetectorRef, inject } from '@angular/core';
import { allCountries } from 'app/models/country';
import { Clipboard } from '@angular/cdk/clipboard';
import { RouteType } from 'app/routing/route-type';
import { LocaleService } from 'app/services/locale.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.less'],
})
export class SettingsComponent extends BaseComponent implements OnInit {
    users: GemUser[] = [];
    allGemUserRoles = allGemUserRoles;
    allCountries = allCountries;
    showAddCountryForUserId = '';
    showAddLocaleForUserId = '';

    get allLocales() {
        return this.localeService.locales;
    }

    private gemUserApiService = inject(GemUserApiService);
    private localeService = inject(LocaleService);
    private clipboard = inject(Clipboard);
    private cd = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this.localeService.changed.pipe(takeUntil(this.destroyed$)).subscribe(_ => this.cd.markForCheck());
        this.gemUserApiService.getUsers().subscribe(res => {
            this.users = res.data;
        });
    }

    changeRole(user: GemUser) {
        this.gemUserApiService.updateUser(user.id, { role: user.role }).subscribe(() => {
            if (user.id === this.gemUser.id && user.role !== GemUserRole.admin) {
                this.navigationService.navigateTo(RouteType.users).then(() => this.navigationService.reload());
            }
        });
    }

    localesToAdd(user: GemUser) {
        return this.allLocales.filter(item => !user.locales.some(locale => locale.code === item.code));
    }

    deleteLocale(user: GemUser, localeCode: number) {
        if (user.locales.length > 1) {
            this.updateLocales(
                user.id,
                user.locales.map(item => item.localeId).filter(item => item !== localeCode),
            );
        }
    }

    addLocale(user: GemUser, localeCode: number) {
        this.showAddLocaleForUserId = '';
        this.updateLocales(user.id, [...user.locales.map(item => item.localeId), localeCode]);
    }

    private updateLocales(userId: string, locales: number[]) {
        this.gemUserApiService.updateUser(userId, { locales }).subscribe(res => {
            const index = this.users.findIndex(item => item.id === userId);
            if (index > -1) {
                this.users[index] = res.data;
            }
        });
    }

    countriesToAdd(user: GemUser) {
        return allCountries.filter(item => !user.countries.some(country => country.countryCode === item));
    }

    deleteCountry(user: GemUser, countryCode: string) {
        if (user.countries.length > 1) {
            this.updateCountries(
                user.id,
                user.countries.map(item => item.countryCode).filter(item => item !== countryCode),
            );
        }
    }

    addCountry(user: GemUser, countryCode: string) {
        this.showAddCountryForUserId = '';
        this.updateCountries(user.id, [...user.countries.map(item => item.countryCode), countryCode]);
    }

    private updateCountries(userId: string, countries: string[]) {
        this.gemUserApiService.updateUser(userId, { countries }).subscribe(res => {
            const index = this.users.findIndex(item => item.id === userId);
            if (index > -1) {
                this.users[index] = res.data;
            }
        });
    }

    resetLogin(userId: string) {
        this.gemUserApiService.resetLogin(userId).subscribe(secret => {
            this.showAlert({
                title: 'New secret',
                description: secret,
                dismissButtonTitle: 'Close',
                confirmButtonTitle: 'Copy',
            }).componentInstance.onConfirm.subscribe(() => {
                this.clipboard.copy(secret);
            });
        });
    }

    removeUser(userId: string) {
        this.showAlert({
            title: 'Are you sure you want to remove this GEM user?',
            confirmButtonTitle: 'Yes, remove',
        }).componentInstance.onConfirm.subscribe(() => {
            this.gemUserApiService.deleteUser(userId).subscribe(() => {
                this.users = this.users.filter(item => item.id !== userId);
            });
        });
    }
}
