import { StorageService } from 'app/services/storage.service';
import { Injectable, signal } from '@angular/core';
import { RouteType, allRouteTypes } from 'app/routing/route-type';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    routeTypeSignal = signal<RouteType>(RouteType.login);
    allSubRoutesSignal = signal<string[]>([]);

    get routeType(): RouteType {
        if (!this.routeTypeCache || this.routeTypeCache.url !== this.router.url) {
            const url = this.router.url;
            this.routeTypeCache = { url, type: this.getRouteType(url) };
        }
        return this.routeTypeCache.type;
    }
    get allSubRoutes() {
        const urlTree = this.router.parseUrl(this.router.url);
        const modalPath = urlTree?.root?.children?.modal?.segments?.[0]?.path as RouteType;
        const path = modalPath ?? this.router.url;
        return path.split('/').map(item => item.split('?')[0]);
    }
    private routeTypeCache?: { url: string; type: RouteType };
    private countryAgnosticRoutes = [RouteType.login, RouteType.settings, RouteType.addGemUser, RouteType.translations];

    constructor(
        private router: Router,
        private storageService: StorageService,
    ) {
        this.router.events.subscribe(_ => {
            this.routeTypeSignal.set(this.routeType);
            this.allSubRoutesSignal.set(this.allSubRoutes);
        });
    }

    navigateTo(routeType: RouteType) {
        return this.router.navigate([
            `${this.countryAgnosticRoutes.includes(routeType) ? '' : this.storageService.countryCode}/${routeType}`,
        ]);
    }

    navigateToDefaultSignedInRoute() {
        return this.navigateTo(RouteType.users);
    }

    navigateByUrl(url: string) {
        return this.router.navigateByUrl(url);
    }

    goUp() {
        this.router.navigateByUrl(this.router.url.substring(0, this.router.url.lastIndexOf('/')));
    }

    getRouteType(url: string): RouteType {
        const urlTree = this.router.parseUrl(url);
        const modalPath = urlTree?.root?.children?.modal?.segments?.[0]?.path as RouteType;
        return modalPath ?? this.getRouteTypeFromPlainUrl(url);
    }

    reload() {
        window.location.reload();
    }

    // ---- Internal ---- //
    private getRouteTypeFromPlainUrl(url: string) {
        const first = url.split('/')[1] as RouteType;
        const second = url.split('/')[2] as RouteType;
        const path = this.countryAgnosticRoutes.includes(first) ? first : second;
        return allRouteTypes.includes(path) ? path : RouteType.notFound;
    }
}
