export enum CountryCode {
    argentina = 'ar',
    belgium = 'be',
    brazil = 'br',
    canada = 'ca',
    colombia = 'co',
    germany = 'de',
    denmark = 'dk',
    finland = 'fi',
    india = 'in',
    italy = 'it',
    malaysia = 'my',
    mexico = 'mx',
    netherlands = 'nl',
    norway = 'no',
    spain = 'es',
}
export const allCountries = Object.values(CountryCode);
