<div class="profile-section">
    <div class="section-row info-section">
        Status:
        <div *ngIf="sendingEmail">sending email...</div>
        <div *ngIf="showEmailConfirmation" class="email-sent-confirmation">Email sent</div>
    </div>

    <div class="section-header">Send:</div>

    <button class="btn-link" (click)="sendEmail('personal-data-warning')">warning email</button>
    <br /><br />
    <button class="btn-link" (click)="sendEmail('reactivation')">reactivation email</button>
    <br /><br />
    <button class="btn-link" (click)="sendEmail('complete-registration-reminder')">complete registration reminder</button>
    <br /><br />

    <div *ngIf="user.email?.endsWith('@sitly.com')" class="section-row">
        <button class="btn-link" (click)="sendEmail('matchmail')">matchmail</button>
    </div>
</div>
