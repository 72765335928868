import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BaseComponent } from 'app/components/base.component';
import { routesAnimation } from 'app/routing/animations';
import { ApiInterceptor } from 'app/services/api/api.service';
import { ApiError } from 'app/types';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
    animations: [routesAnimation],
})
export class AppComponent extends BaseComponent {
    title = 'gem';
    errorMessage?: object;
    showNotAllowed = false;

    ngOnInit() {
        ApiInterceptor.onError.subscribe(error => {
            this.errorMessage = {
                url: error.url,
                error: (error as ApiError).error?.errors ?? error.error,
            };
            setTimeout(() => {
                this.errorMessage = undefined;
            }, 6_000);
        });

        this.sessionService.onNotAllowedCountry.subscribe(() => {
            this.showNotAllowed = true;
        });
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet?.activatedRouteData?.animation as unknown;
    }

    closeNotAllowedOverlay() {
        this.showNotAllowed = false;
        this.sessionService.switchCountry(this.gemUser.countries[0].countryCode);
    }
}
