import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { UserRole } from 'app/models/api/user';
import { WarningLevel, WarningType } from 'app/models/api/warning';
import { UserWarningApiService, WarnedUserAction } from 'app/services/api/user.warning.api.service';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription, zip } from 'rxjs';
import { RoundCheckboxComponent } from 'app/components/common/round-checkbox/round-checkbox.component';
import { ScreeningGeneralSubRouteType } from 'app/routing/route-type';
import { GemSelectOption } from 'app/components/common/gem-select/gem-select.component';
import { BaseComponent } from 'app/components/base.component';
import { UserApiService } from 'app/services/api/user.api.service';
import { ServerResponse } from 'app/models/api/response';
import { SelectableUser, SelectableUserPhoto } from 'app/components/screening/general/general.component.models';

type FilterType = UserRole | WarningType;

@Component({
    selector: 'screening-general',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.less'],
})
export class ScreeningGeneralComponent extends BaseComponent implements OnInit, OnDestroy {
    greyListUsersNumber = 0;
    blackListUsersNumber = 0;
    suspectedUsersNumber = 0;
    users: SelectableUser[] = [];
    finishedPaging = false;

    selectedTab?: ScreeningGeneralSubRouteType;
    ScreeningGeneralSubRouteType = ScreeningGeneralSubRouteType;

    selectedFilter?: FilterType;
    showFilterOptions = false;

    snackBarMessage?: string;
    snackBarVisible = false;

    WarningType = WarningType;

    @ViewChild('selectAll', { static: false }) selectAllInput?: RoundCheckboxComponent;

    get pageSize() {
        return this.selectedFilter === WarningType.avatar ? 50 : 10;
    }
    get hasSelectedUsers() {
        return this.users.find(item => item.selected);
    }
    get selectedUsersHaveAvatar() {
        return this.users.find(item => item.selected && item.user.links?.avatar);
    }
    get selectedUsersNumber() {
        return this.users.filter(item => item.selected).length;
    }
    get isOnlyPhotosView() {
        return this.selectedFilter === WarningType.avatar;
    }
    filterOptions = [
        { id: undefined, label: 'Show All' },
        { id: WarningType.avatar, label: 'Show only photos' },
        { id: WarningType.message, label: 'Show only messages' },
        { id: WarningType.male, label: 'Show only males' },
        { id: WarningType.spam, label: 'Show only spam' },
        { id: WarningType.report, label: 'Show only reported' },
        { id: UserRole.parent, label: 'Show only parents' },
        { id: UserRole.babysitter, label: 'Show only babysitters' },
    ];
    get showSuspectBtn() {
        return this.selectedTab === ScreeningGeneralSubRouteType.blackList || this.selectedTab === ScreeningGeneralSubRouteType.greyList;
    }
    get hasSelectedSitters() {
        return this.users.filter(item => item.selected).some(item => !item.user.isParent);
    }
    get flaggedPhotos() {
        return this.users.flatMap(item => item.photos.filter(photo => !!photo.warning));
    }

    private urlSubscription?: Subscription;
    private refreshInterval?: number;

    private userWarningApiService = inject(UserWarningApiService);
    private userApiService = inject(UserApiService);
    private router = inject(Router);

    ngOnInit() {
        this.urlSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateOnUrlChange(event.url);
            }
        });

        this.updateOnUrlChange(this.router.url);
        this.refreshInterval = setInterval(() => this.refreshStats(), 60_000);
    }

    private updateOnUrlChange(url: string) {
        const urlParts = url.split('/');
        this.selectedTab = ScreeningGeneralSubRouteType[urlParts[urlParts.length - 1] as never];
        this.restartSearch();
    }

    ngOnDestroy() {
        this.urlSubscription?.unsubscribe();
        clearInterval(this.refreshInterval);
    }

    onScroll() {
        if (this.selectedTab && !this.finishedPaging) {
            this.getUsers(this.warningLevelForTab(this.selectedTab), this.users[this.users.length - 1]?.user.created).subscribe(res => {
                this.users.push(...res.data.map(user => new SelectableUser(user)));
                this.finishedPaging = res.data.length < this.pageSize;
            });
        }
    }

    deleteAvatar(event: Event, userId: string) {
        event.stopPropagation();
        event.preventDefault();
        this.deleteUserAvatars([userId]);
    }

    deletePhoto(event: Event, userId: string, photo: SelectableUserPhoto) {
        event.stopPropagation();
        event.preventDefault();

        if (photo.type === WarningType.avatar) {
            this.deleteAvatar(event, userId);
        } else {
            this.showAlert({
                title: 'Are you sure you want to delete the photo and send warning message to this user?',
                confirmButtonTitle: 'Delete & warn',
            }).componentInstance.onConfirm.subscribe(() => {
                if (photo.id) {
                    this.userApiService.deleteUserPhoto(userId, photo.id).subscribe(() => {
                        this.showSnackBar('User photo deleted and warning message sent');
                        this.restartSearch();
                    });
                }
            });
        }
    }

    ignoreUser(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();
        this.bulkIgnoreUsers([user.user.id]);
    }

    ignoreSelectedUsers() {
        this.bulkIgnoreUsers(this.users.filter(item => item.selected).map(item => item.user.id));
    }

    toggleAllSelection(selected: boolean) {
        this.users.forEach(user => (user.selected = selected));
    }

    toggleUserSelection(selected: boolean, user: SelectableUser) {
        user.selected = selected;
        if (this.selectAllInput) {
            this.selectAllInput.checked = false;
        }
    }

    onFilterSelected(option?: GemSelectOption<FilterType>) {
        this.selectedFilter = option?.id;
        this.showFilterOptions = false;
        this.restartSearch();
    }

    resetFilter() {
        this.onFilterSelected(undefined);
    }

    deleteSelectedAvatars() {
        this.deleteUserAvatars(this.users.filter(item => item.selected).map(item => item.user.id));
    }

    deleteUserAvatar(userId: string) {
        this.deleteUserAvatars([userId]);
    }

    suspectUser(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();
        this.userApiService.updateUser(user.user.id, { suspected: true }).subscribe(_ => this.restartSearch());
    }

    blockUser(event: Event, userId: string) {
        event.stopPropagation();
        event.preventDefault();
        this.bulkBlockUsers([userId]);
    }

    blockSelectedUsers() {
        this.bulkBlockUsers(this.users.filter(item => item.selected).map(item => item.user.id));
    }

    deleteUnderageUser(event: Event, user: SelectableUser) {
        event.stopPropagation();
        event.preventDefault();
        this.bulkDeleteUnderageUsers([user.user.id]);
    }

    deleteUnderageSelectedUsers() {
        this.bulkDeleteUnderageUsers(this.users.filter(item => item.selected && !item.user.isParent).map(item => item.user.id));
    }

    clearUserPhotosUntil(event: Event, lastPhoto: SelectableUserPhoto) {
        event.stopPropagation();
        event.preventDefault();

        const userIds: string[] = [];
        const photoIds: string[] = [];
        for (const item of this.flaggedPhotos) {
            if (item.type === 'avatar') {
                userIds.push(item.id);
            } else if (item.type === 'photo') {
                photoIds.push(item.id);
            }

            if (item.id === lastPhoto.id) {
                break;
            }
        }

        this.bulkDeleteAvatarsAndPhotos(userIds, photoIds);
    }

    private bulkDeleteAvatarsAndPhotos(userIds: string[], photoIds: string[]) {
        if (userIds.length === 0 && photoIds.length === 0) {
            return;
        }

        this.showAlert({
            title: 'Clear all photos until here?',
            description: 'Are you sure you want to clear these photos and all photos before it?',
            confirmButtonTitle: 'Clear photos',
            dismissButtonTitle: 'Cancel',
        }).componentInstance.onConfirm.subscribe(() => {
            const actions: Observable<ServerResponse>[] = [];
            if (userIds.length > 0) {
                actions.push(this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.deleteAvatar, userIds));
            }
            if (photoIds.length > 0) {
                actions.push(this.userWarningApiService.deleteWarnedUsersPhotos(photoIds));
            }
            zip(...actions).subscribe(_ => this.restartSearch());
        });
    }

    private bulkDeleteUnderageUsers(userIds: string[]) {
        this.showAlert({
            title: `Are you sure you want to delete ${userIds.length === 1 ? 'this account' : 'these accounts'}?`,
            confirmButtonTitle: 'Yes, delete',
            dismissButtonTitle: 'Cancel',
        }).componentInstance.onConfirm.subscribe(() => {
            this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.deleteUnderaged, userIds).subscribe(() => {
                const count = userIds.length;
                this.showSnackBar(count > 1 ? `${count} users deleted and warning messages sent` : 'User deleted and warning message sent');

                this.restartSearch();
            });
        });
    }

    private warningLevelForTab(selectedTab: ScreeningGeneralSubRouteType) {
        switch (selectedTab) {
            case ScreeningGeneralSubRouteType.blackList:
                return WarningLevel.severe;
            case ScreeningGeneralSubRouteType.quarantined:
                return WarningLevel.blocked;
            case ScreeningGeneralSubRouteType.greyList:
                return WarningLevel.moderate;
            case ScreeningGeneralSubRouteType.suspects:
                return WarningLevel.suspected;
        }
    }

    private deleteUserAvatars(userIds: string[]) {
        this.showAlert({
            title:
                userIds.length > 1
                    ? 'Are you sure you want to delete all photos and send warning messages to these users?'
                    : 'Are you sure you want to delete the photo and send warning message to this user?',
            confirmButtonTitle: 'Delete & warn',
        }).componentInstance.onConfirm.subscribe(() => {
            this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.deleteAvatar, userIds).subscribe(() => {
                const count = userIds.length;
                this.showSnackBar(
                    count > 1
                        ? `Photos of ${count} users deleted and warning messages sent`
                        : 'User photo deleted and warning message sent',
                );

                this.restartSearch();
            });
        });
    }

    private showSnackBar(message: string) {
        this.snackBarVisible = true;
        this.snackBarMessage = message;

        setTimeout(() => {
            this.snackBarVisible = false;
            this.snackBarMessage = undefined;
        }, 3000);
    }

    private bulkIgnoreUsers(userIds: string[]) {
        this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.ignore, userIds).subscribe(() => {
            this.restartSearch();

            const part1 = `${userIds.length} ${userIds.length > 1 ? 'users' : 'user'}`;
            const part2 = 'removed from ';
            let part3 = 'qrey list';
            if (this.selectedTab === ScreeningGeneralSubRouteType.quarantined) {
                part3 = 'quarantine';
            } else if (this.selectedTab === ScreeningGeneralSubRouteType.blackList) {
                part3 = 'black list';
            }

            this.showSnackBar(`${part1} ${part2} ${part3}`);
        });
    }

    private restartSearch() {
        if (this.selectedTab) {
            this.finishedPaging = false;
            this.getUsers(this.warningLevelForTab(this.selectedTab)).subscribe(response => {
                this.users = response.data.map(user => new SelectableUser(user));
                this.finishedPaging = response.data.length < this.pageSize;
            });
        }

        this.refreshStats();
    }

    private getUsers(warningLevel: WarningLevel, createdBefore?: string) {
        return this.userWarningApiService.getWarnedUsers(warningLevel, {
            page: { limit: this.pageSize, createdBefore },
            warningType: this.filterWarningType(),
            role: this.filterUserRole(),
        });
    }

    private filterWarningType() {
        return Object.values(WarningType).includes(this.selectedFilter as WarningType) ? (this.selectedFilter as WarningType) : undefined;
    }

    private filterUserRole() {
        return Object.values(UserRole).includes(this.selectedFilter as UserRole) ? (this.selectedFilter as UserRole) : undefined;
    }

    private refreshStats() {
        this.userWarningApiService.getWarnedUsersStats().subscribe(response => {
            this.greyListUsersNumber = response.moderateWarningUserCount;
            this.blackListUsersNumber = response.severeWarningUserCount;
            this.suspectedUsersNumber = response.suspectedUserCount;
        });
    }

    private bulkBlockUsers(userIds: string[]) {
        this.userWarningApiService.postWarnedUsersAction(WarnedUserAction.quarantine, userIds).subscribe(() => {
            this.restartSearch();

            const part1 = `${userIds.length} ${userIds.length > 1 ? 'users' : 'user'}`;
            const part2 = 'moved to quarantine';
            this.showSnackBar(`${part1} ${part2}`);
        });
    }
}
