import { UserApiService } from 'app/services/api/user.api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'app/models/api/user';
import { of, zip } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ServerResponse, ServerResponseData } from 'app/models/api/response';
import { Child } from 'app/models/api/child';
import { ChildApiService } from 'app/services/api/child.api.service';
import { StorageService } from './storage.service';

interface Address {
    placeName: string;
    streetName: string;
    houseNumber: string;
    postalCode: string;
}

interface AddressMap {
    [countryCode: string]: Address;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    get countrySettings() {
        return this.storageService.countrySettings;
    }

    constructor(
        private storageService: StorageService,
        private userApiService: UserApiService,
        private childService: ChildApiService,
        private http: HttpClient,
    ) {}

    loginAsUser(userId: string) {
        return this.userApiService.getTempToken(userId).subscribe(token => {
            if (token && this.countrySettings?.desktopBackendUrl) {
                window.open(`${this.countrySettings.desktopBackendUrl}?tempToken=${token}`, '_blank');
            }
        });
    }

    createUser(email: string, firstName: string, lastName: string, role: string, countryCode: string, password = '12345678') {
        return this.userApiService.createUser(email, password, firstName, lastName, role).pipe(
            switchMap(response => this.prepareUserData(email, password, role, response.data)),
            switchMap(response => this.fillUserProperties(response, countryCode)),
        );
    }

    private prepareUserData(email: string, password: string, role: string, user: User) {
        return zip(
            of(user),
            this.userApiService.getAuthToken(email, password),
            this.http.get(`assets/json/${role}.json`),
            this.http.get('assets/json/addresses.json'),
            (user: User, response: ServerResponse, userData: Partial<User>, addresses: AddressMap) => ({
                user,
                authToken: (response.data as ServerResponseData).attributes.token as string,
                userData,
                addresses,
            }),
        );
    }

    private fillUserProperties(
        data: {
            user: User;
            authToken: string;
            userData: Partial<User>;
            addresses: AddressMap;
        },
        countryCode: string,
    ) {
        Object.assign(data.userData, data.addresses[countryCode]);

        return this.userApiService.updateSitlyUser(data.userData, data.authToken).pipe(
            switchMap(_ => this.addUserRelationShips(data.user, data.authToken)),
            map(_ => data.user),
        );
    }

    private addUserRelationShips(user: User, authToken: string) {
        if (!user.isParent) {
            return of(user);
        }

        return this.http.get('assets/json/children.json').pipe(
            switchMap(children =>
                zip(...this.addChildren(children as Child[], authToken), (child1: ServerResponse, child2: ServerResponse) => ({
                    child1,
                    child2,
                })),
            ),
            map(_ => user),
        );
    }

    private addChildren(children: Child[], authToken: string) {
        return children.map(child => this.childService.saveChild(child, authToken));
    }
}
