import { BaseComponent } from 'app/components/base.component';
import { Environment, TranslationApiService } from 'app/services/api/translation.api.service';
import { LocaleService } from 'app/services/locale.service';
import { Component, ChangeDetectorRef, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
    template: '',
})
export class BaseTranslationComponent extends BaseComponent {
    get localeOptions() {
        return this.localeService.locales;
    }
    get environmentOptions() {
        return environment.name === 'prod'
            ? [Environment.acceptance, Environment.production]
            : [Environment.development, Environment.acceptance];
    }

    protected route = inject(ActivatedRoute);
    protected translationService = inject(TranslationApiService);
    protected localeService = inject(LocaleService);
    protected cd = inject(ChangeDetectorRef);

    ngOnInit() {
        this.localeService.changed.pipe(takeUntil(this.destroyed$)).subscribe(_ => this.cd.markForCheck());
    }
}
