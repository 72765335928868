import { Component, OnDestroy, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { RouteType } from 'app/routing/route-type';
import { AlertDialogContentComponent, AlertDialogDate } from 'app/components/common/alert-dialog/alert-dialog.component';
import { NavigationService } from 'app/services/navigation.service';
import { SessionService } from 'app/services/session.service';
import { StorageService } from 'app/services/storage.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    template: '',
})
export abstract class BaseComponent implements OnDestroy {
    math = Math;
    RouteType = RouteType;

    readonly navigationService = inject(NavigationService);
    readonly sessionService = inject(SessionService);
    readonly storageService = inject(StorageService);
    readonly matDialog = inject(MatDialog);

    get gemUser() {
        return this.sessionService.user;
    }
    get countrySettings() {
        return this.storageService.countrySettings;
    }

    protected destroyed$ = new Subject<void>();

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    showAlert(data: AlertDialogDate) {
        return this.matDialog.open(AlertDialogContentComponent, AlertDialogContentComponent.config(data));
    }
}
