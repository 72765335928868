<div class="container">
    <div class="top-bar">
        <div class="group-container">
            <select #group name="group-id" id="group-id" [(ngModel)]="groupId" (change)="onInputChanged()">
                <option *ngFor="let group of groups" id="{{ group.groupId }}" value="{{ group.groupId }}">
                    {{ group.name }}
                </option>
            </select>
        </div>

        <div class="buttons-right-container">
            <button *ngIf="gemUser.isAdmin" class="btn-solid-hibiscus" [disabled]="loading" [routerLink]="translationsPublishRouterLink">
                Publish texts
            </button>
        </div>
    </div>

    <div class="language-bar">
        <div class="search-container">
            <input
                #searchInput
                type="text"
                class="search-field"
                placeholder="Search"
                (keyup.enter)="onInputChanged()"
                [(ngModel)]="searchQuery"
            />
            <button class="btn-solid-green btn-go" [disabled]="loading" (click)="onInputChanged()">Go</button>
        </div>

        <div class="language-container">
            <select #sourceLocale name="source-locale" id="source-locale" [(ngModel)]="sourceLocaleId" (change)="onInputChanged()">
                <option *ngFor="let locale of localeOptions" id="{{ locale.code }}" value="{{ locale.id }}">
                    {{ locale.name }}
                </option>
            </select>
        </div>

        <div class="language-container">
            <select #targetLocale name="target-locale" id="target-locale" [(ngModel)]="targetLocaleId" (change)="onInputChanged()">
                <option *ngFor="let locale of localeOptions" id="{{ locale.code }}" value="{{ locale.id }}">
                    {{ locale.name }}
                </option>
            </select>
            <round-checkbox [checked]="untranslated" (changed)="onUntranslatedToggled($event)"></round-checkbox>
            <label for="">Untranslated only</label>
        </div>

        <!-- empty grid cell -->
        <div></div>
    </div>

    <div class="content">
        <div class="translation-item" *ngFor="let translation of translations">
            <div class="translation-code">
                {{ translation.translationCode }}
            </div>
            <div class="translation-value" [innerText]="translation.sourceValue ?? ''"></div>
            <div class="translation-input">
                <textarea
                    [disabled]="!editAllowed"
                    [(ngModel)]="translation.targetValue"
                    (keyup)="translation.modified = true"
                    (blur)="saveChanges(translation)"
                ></textarea>
                <div class="modified">
                    <span *ngIf="translation.modified">*</span>
                </div>
            </div>
            <button
                *ngIf="gemUser.isAdmin"
                class="btn-solid-blue btn-delete"
                (click)="showDeleteTranslationDialog(translation.translationCodeId)"
            >
                <img src="assets/images/delete-white.svg" />
            </button>
        </div>
    </div>

    <div *ngIf="totalCount > 0">
        <mat-paginator
            [length]="totalCount"
            [pageSize]="pageSize"
            [pageIndex]="pageNumber - 1"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="[10, 25, 50, 100]"
            (page)="onPageChanged($event)"
            aria-label="Select page"
        >
        </mat-paginator>
    </div>
</div>
