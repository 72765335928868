import { Injectable } from '@angular/core';
import { PaginationMeta, Translation } from 'app/models/api/translation';
import { TranslationGroup } from 'app/models/api/translation-group';
import { ApiService, ParamsMap } from 'app/services/api/api.service';
import { ResponseParser } from 'app/services/api/parsers/response-parser';
import { map } from 'rxjs/operators';

export type PaginationParams = {
    number: number;
    size: number;
};

export type TranslationSearchFilter = {
    groupId?: number;
    keyword?: string;
    countryId?: string;
};

export enum Environment {
    development = 'development',
    acceptance = 'acceptance',
    production = 'production',
}

@Injectable({
    providedIn: 'root',
})
export class TranslationApiService {
    constructor(
        private apiService: ApiService,
        private responseParser: ResponseParser,
    ) {}

    getTranslations(localeIds: number[], pageParams: PaginationParams, filter: TranslationSearchFilter) {
        return this.apiService
            .get('/gem/translations', {
                params: {
                    localeIds: localeIds.join(','),
                    ...(filter ? { filter } : {}),
                    page: pageParams,
                },
                mainSubpath: true,
            })
            .pipe(map(response => this.responseParser.parseObject<Translation[], PaginationMeta>(response)));
    }

    addTranslation(translation: Partial<Translation>) {
        return this.apiService.post('/gem/translations', {
            body: translation,
            mainSubpath: true,
        });
    }

    updateTranslations(
        localeId: number,
        values: {
            translationCodeId: number;
            content: string;
        }[],
    ) {
        return this.apiService.patch(`/gem/translations/${localeId}`, {
            body: values,
            mainSubpath: true,
        });
    }

    deleteTranslation(translationCodeId: number) {
        return this.apiService.delete(`/gem/translations/${translationCodeId}`, { mainSubpath: true });
    }

    publishTranslations(source: Environment, target: Environment, translationValueIds: number[]) {
        return this.apiService.post('/gem/translations/environments', {
            body: {
                source,
                target,
                translationValueIds,
                action: 'publish',
            },
            mainSubpath: true,
        });
    }

    getTranslationDifference(
        source: Environment,
        target: Environment,
        pageParams: Partial<PaginationParams>,
        localeIds?: number[],
        filter?: Record<string, string>,
    ) {
        const params: ParamsMap = {
            source,
            target,
            page: pageParams,
        };
        if (localeIds) {
            params.localeIds = localeIds.join(',');
        }
        if (filter) {
            params.filter = filter;
        }
        return this.apiService
            .get('/gem/translations/diff', { params, mainSubpath: true })
            .pipe(map(response => this.responseParser.parseObject<Translation[], PaginationMeta>(response)));
    }

    // --- Translation groups requests --- //
    getGroups() {
        return this.apiService
            .get('/gem/translation-groups', { mainSubpath: true })
            .pipe(map(response => this.responseParser.parseObject<TranslationGroup[]>(response)));
    }

    saveGroup(groupName: string) {
        return this.apiService
            .post('/gem/translation-groups', {
                body: {
                    groupName,
                },
                mainSubpath: true,
            })
            .pipe(map(response => this.responseParser.parseObject<TranslationGroup>(response)));
    }
}
