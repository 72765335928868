<div class="page-container">
    <div *ngIf="user && selectedTab !== UserSubRouteType.matches" class="tab-container">
        <div class="tab-header" [class.selected]="selectedTab === UserSubRouteType.profile" [routerLink]="UserSubRouteType.profile">
            Profile
        </div>
        <div
            class="tab-header"
            *ngIf="user.completed"
            [class.selected]="selectedTab === UserSubRouteType.subscription"
            [routerLink]="UserSubRouteType.subscription"
        >
            Subscription
        </div>
        <div
            class="tab-header"
            *ngIf="user.completed && !user.deleted"
            [class.selected]="selectedTab === UserSubRouteType.messages || selectedTab === UserSubRouteType.chat"
            [routerLink]="UserSubRouteType.messages"
        >
            Messages
            <div class="counter">{{ user.meta?.conversationsCount ?? 0 }}</div>
        </div>
        <div
            class="tab-header"
            *ngIf="user.completed && !user.isParent"
            [class.selected]="selectedTab === UserSubRouteType.reviews"
            [routerLink]="UserSubRouteType.reviews"
        >
            Reviews
            <div class="counter">{{ user.recommendations.length }}</div>
        </div>
        <div class="tab-header" [class.selected]="selectedTab === UserSubRouteType.logs" [routerLink]="UserSubRouteType.logs">Logs</div>
        <div *ngIf="gemUser.role === GemUserRole.admin && !user.deleted" class="tab-header" [routerLink]="UserSubRouteType.matches">
            Matches
        </div>
        <div
            *ngIf="gemUser.role === GemUserRole.admin && !user.deleted"
            class="tab-header"
            [class.selected]="selectedTab === UserSubRouteType.emails"
            [routerLink]="UserSubRouteType.emails"
        >
            Emails
        </div>

        <div class="actions-container" (clickOutside)="showActionOptions = false">
            <button type="button" class="btn-options" (click)="onUserActionsClicked()">User actions</button>
            <div class="action-options" *ngIf="showActionOptions" (click)="showActionOptions = false">
                <div class="option" *ngIf="user.active" (click)="save({ active: false })">Block user</div>
                <div class="option" *ngIf="!user.active" (click)="save({ active: true })">Unblock user</div>
                <div class="option" (click)="onQuarantineClicked()">
                    {{ user.quarantinedAt ? 'Un-quarantine user' : 'Quarantine user' }}
                </div>
                <div class="option" (click)="onDeleteClicked()">Delete user</div>
                <div class="option" *ngIf="!user.disabled" (click)="save({ disabled: true })">Hide user</div>
                <div class="option" *ngIf="user.disabled" (click)="save({ disabled: false })">Unhide user</div>
                <div class="option" (click)="save({ suspected: !user.suspected })">
                    {{ user.suspected ? 'Un-suspect user' : 'Suspect user' }}
                </div>
                <div class="option" (click)="userService.loginAsUser(user.id)">Log in as user</div>
            </div>
        </div>

        <span class="save-icon" [class.saved]="savedShown">{{ 'Saved' }}</span>
    </div>

    <div
        *ngIf="user"
        class="container"
        [class.wide]="selectedTab === UserSubRouteType.logs"
        [class.extra-wide]="selectedTab === UserSubRouteType.matches"
    >
        <div *ngIf="user.deleted" class="deleted-user">User is deleted</div>
        <profile-tab *ngIf="selectedTab === UserSubRouteType.profile" [user]="user" (onUserUpdated)="onUserUpdated($event)"></profile-tab>
        <subscription-tab
            *ngIf="selectedTab === UserSubRouteType.subscription"
            [user]="user"
            (onUserUpdated)="onUserUpdated($event)"
        ></subscription-tab>
        <messages-tab
            *ngIf="selectedTab === UserSubRouteType.messages"
            [user]="user"
            (onUserUpdated)="onUserUpdated($event)"
        ></messages-tab>
        <chat *ngIf="selectedTab === UserSubRouteType.chat" [user]="user" (onUserUpdated)="onUserUpdated($event)"></chat>
        <reviews-tab *ngIf="selectedTab === UserSubRouteType.reviews" [user]="user" (onUserUpdated)="onUserUpdated($event)"></reviews-tab>
        <logs-tab *ngIf="selectedTab === UserSubRouteType.logs" [user]="user" (onUserUpdated)="onUserUpdated($event)"></logs-tab>
        <matches-tab *ngIf="selectedTab === UserSubRouteType.matches" [user]="user"></matches-tab>
        <emails-tab *ngIf="selectedTab === UserSubRouteType.emails" [user]="user"></emails-tab>
    </div>
</div>
